import axios from "axios"

const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const carrierServicesUrl = `${process.env.VUE_APP_FUSION_API_URL}/carriers/carrier_services`;
// const carrierServicesUrl = "http://3plapi.test/carriers/carrier_services"
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"
export default {
  async getCarrierServices(carrierId) {        
    let res = await axios.get(`${carrierServicesUrl}?API-KEY=${API_KEY}&carrier_id=${carrierId}`);    
    return res.data.data;
  },
  async getAllCarrierServices() {        
    let res = await axios.get(`${carrierServicesUrl}?API-KEY=${API_KEY}`);    
    return res.data.data;
  },
  async updateService(formData) {
    var myHeaders = new Headers();    
    let formArray = formData;    
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formArray,
      redirect: 'follow'
    };

    let result = await fetch(carrierServicesUrl, requestOptions)
      .then(response => response.text())
      .then(result => this.result = result)
    return result;
  },
  async createCarrierService(formData) {
    var myHeaders = new Headers();    
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formArray,
      redirect: 'follow'
    };
    let result = await fetch(carrierServicesUrl, requestOptions)
      .then(response => response.text())
      .then(result => this.result = result)
    return result;
  },
  async deleteCarrierService(formData) {
    var myHeaders = new Headers();    
    let formArray = formData;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formArray,
      redirect: 'follow'
    };
    let result = await fetch(carrierServicesUrl, requestOptions)
      .then(response => response.text())
      .then(result => this.result = result)
    return result;
  },
}

