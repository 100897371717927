<template>
  <div class="billingSnap">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{title}}</h1>        
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form v-model="valid" class="ma-2">
          <v-container>
            <v-row class="control columns">
              <div class="column is-one-quarter">
                <div class="field">
                  <label for="carrier">Carrier: </label>
                  <div class="control">
                    <div class="select">
                      <select name="carrier" id="carriers" v-model="selectedCarrier" v-on:change="getServices()">
                        <option v-for="carrier in carriers" v-bind:value="carrier.id" :key="carrier.id">{{carrier.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div class="billingTable" v-if="carrier != null">
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn 
              color="primary" 
              dark class="mb-2" 
              v-on="on" 
              v-on:click="setCarrier()"
              :disabled="disabled == false">
              Add new Carrier Service
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.name" disabled label="Carrier Name:"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.service" label="Service:"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="carrierServices"
            :items-per-page="10"
            :search="search"
            id="data-table">
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card> 
      </div>
    </div>
    <v-snackbar
    v-model="snackbar"
    :timeout=5000
    >
    {{ text }}
    <v-btn
    color="blue"
    text
    @click="snackbar = false"
    >
    </v-btn>
    </v-snackbar>   
  </div>
</template>
<script>
import Services from '@/services/Services.js';
import Carriers from '@/services/Carriers.js';

export default {
  data () {
    return {
      // Title
      title: 'Services',
      // Snackbar
      snackbar: false,
      text: '',
      returnMessage: null,
      disabled: false,
      dialog: false,
      loading: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        carrier_id: null,
        service: null,
        default_billing_type: 1,
      },
      search: '',
      carrier: null,
      carriers: [],
      services: [],
      selectedCarrier: null,
      carrierServices: [],
      valid: false,
      headers: [
        {
          text: 'Carrier Name',
          align: 'left',
          value: 'name',
        },
        { text: 'Service', value: 'service' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    // Sorting
    sortFunc(array) {
      return array.slice().sort(function(a, b){
        return (a.name > b.name) ? 1 : -1;
      });
    },
    save () {
      let urlencoded = new URLSearchParams();
      urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);   
      if (this.editedIndex > -1) {
        urlencoded.append('carrier_id', this.carrier.id)
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const value = this.editedItem[key];
            urlencoded.append(key, value)
          }
        }
        urlencoded.append('carrier_id', this.carrier.id)
        Services.updateService(urlencoded)
        this.text = `Edited ${this.editedItem.service} Service`
      } else {  
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const value = this.editedItem[key];
            urlencoded.append(key, value)
          }
        }
        Services.createCarrierService(urlencoded).then(
          this.text = `Created ${this.editedItem.service} for ${this.carrier.name}`
        )
      }
      this.close()
      setTimeout(() => {
        this.snackbar = true;
        this.getServices()
      }, 750)
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.carrierServices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true
    },
    async deleteItem (item) {      
      if (confirm('Are you sure you want to delete this item?')) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", item.id);
        Services.deleteCarrierService(urlencoded)
        .then(
          (returnMessage => {
            this.snackbar = true;
            this.text = `Deleted ${this.carrier.name} Services`
            this.$set(this, "returnMessage", returnMessage);
            this.loading = false
          }).bind(this)
        );
        setTimeout(() => {
          this.getServices()
        }, 1000)
      }
      
    },
    setCarrier () {
      this.editedItem.name = this.carrier.name;
      this.editedItem.carrier_id = this.carrier.id;
    },
    async getData() {
      return Carriers.getCarriers()
      .then(
        (carriers => {
          this.$set(this, "carriers", carriers);
          this.carriers = this.sortFunc(this.carriers)
          return true;
        }).bind(this)
      );
    },
    async getServices() {
      this.loading = true;
      this.disabled = true;
      this.carrier = this.carriers.find(carrier => carrier.id === this.selectedCarrier)
      // Use the BillingSnap to call the getBillingDataDaterange(dateShippedFrom, dateShippedTo) method
      Services.getCarrierServices(this.selectedCarrier)
      .then(
        (carrierServices => {
          this.$set(this, "carrierServices", carrierServices);
          this.loading = false
        }).bind(this)
      );
    },
    async loadInitialData() {
      this.loading = true;
      let services = await this.getData();      
      if (services) {
        this.loading = false;
        this.text = `Select Services by Carrier and Edit or Delete.`
        this.snackbar = true;
      }      
    }
  },
  created() {    
    this.loadInitialData()
  },
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
  label {
    display: flex;
    align-items: center;
  }
  .button-end {
    align-self: flex-end;
  }
</style>